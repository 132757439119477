import React from 'react';
import { Box, Typography } from '@mui/material';

interface ErrorMessageProps {
  message: string;
}

export default function ErrorMessage({ message }: ErrorMessageProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography color="error">{message}</Typography>
    </Box>
  );
}
