import React, { useState } from 'react';
import GppMaybe from '@mui/icons-material/GppMaybe';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { categoryAliases, displayNameOverrides, Severity } from '@promptfoo/redteam/constants';
import type { DashboardEvalDTO } from '@shared/dto';
import { processCategoryData } from './utils';

interface NewIssuesListProps {
  evals: DashboardEvalDTO[];
  navigateToIssues: (params: Record<string, string>) => void;
  currentEvalIndex: number;
}

export default function NewIssuesList({
  evals,
  navigateToIssues,
  currentEvalIndex,
}: NewIssuesListProps) {
  const currentEval =
    currentEvalIndex >= 0 && currentEvalIndex < evals.length ? evals[currentEvalIndex] : null;
  const previousEval =
    currentEvalIndex >= 0 && currentEvalIndex < evals.length ? evals[currentEvalIndex + 1] : null;

  const evalsToProcess = [currentEval, previousEval].filter(Boolean) as DashboardEvalDTO[];

  const categoryData = evalsToProcess.length > 0 ? processCategoryData(evalsToProcess) : {};

  const [page, setPage] = useState(1);
  const itemsPerPage = 5; // Adjust this value as needed

  const sortedByFailureRate = React.useMemo(() => {
    const severityOrder = [Severity.Critical, Severity.High, Severity.Medium, Severity.Low];

    // Group categories by severity
    const categoriesBySeverity = severityOrder.reduce(
      (acc, severity) => {
        acc[severity] = Object.entries(categoryData).filter(
          ([, data]) =>
            data.severity === severity &&
            data.currentFailCount > 0 &&
            data.historicalFailCount === 0,
        );
        return acc;
      },
      {} as Record<Severity, [string, (typeof categoryData)[string]][]>,
    );

    // Sort within each severity group and combine
    return severityOrder.flatMap((severity) =>
      categoriesBySeverity[severity].sort(
        ([, a], [, b]) =>
          b.currentFailCount / b.currentTotalCount - a.currentFailCount / a.currentTotalCount,
      ),
    );
  }, [categoryData]);

  const pageCount = Math.ceil(sortedByFailureRate.length / itemsPerPage);
  const displayedCategories = sortedByFailureRate.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <GppMaybe color="error" sx={{ mr: 1 }} />
        <Typography variant="h6">
          New Vulnerabilities Discovered ({sortedByFailureRate.length})
        </Typography>
      </Box>
      <List>
        {displayedCategories.map(([category, data], index) => {
          const displayName =
            displayNameOverrides[category as keyof typeof displayNameOverrides] ||
            categoryAliases[category as keyof typeof categoryAliases] ||
            category;

          const failPercentage =
            data.currentTotalCount === 0
              ? 0
              : (data.currentFailCount / data.currentTotalCount) * 100;

          const severityColor = {
            [Severity.Critical]: 'error',
            [Severity.High]: 'warning',
            [Severity.Medium]: 'info',
            [Severity.Low]: 'success',
          };

          return (
            <ListItem
              key={index}
              onClick={() => navigateToIssues({ weakness: displayName })}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'action.hover',
                  transition: 'background-color 0.3s',
                },
                borderBottom: '1px solid #e0e0e0',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <Typography variant="subtitle1">{displayName}</Typography>
                  <Chip
                    label={data.severity}
                    // @ts-expect-error
                    color={severityColor[data.severity]}
                    size="small"
                    sx={{ fontWeight: 'bold' }}
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {`${failPercentage.toFixed(1)}% failing (${data.currentFailCount}/${data.currentTotalCount} probes)`}
                </Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>
      {pageCount > 1 && (
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
        />
      )}
    </Box>
  );
}
