import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface AuthorChipProps {
  author: string | null;
}

export const AuthorChip: React.FC<AuthorChipProps> = ({ author }) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          px: 1,
          py: 0.5,
          '&:hover': {
            bgcolor: 'action.hover',
          },
          minHeight: 40,
        }}
      >
        <EmailIcon fontSize="small" sx={{ mr: 1, opacity: 0.7 }} />
        <Typography variant="body2" sx={{ mr: 1 }}>
          <strong>Author:</strong> {author || 'Unknown'}
        </Typography>
      </Box>
    </>
  );
};
