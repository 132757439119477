import { riskCategories, riskCategorySeverityMap, Severity } from '@promptfoo/redteam/constants';
import type { DashboardEvalDTO } from '@shared/dto';

export function calculateTrend(current: number, previous: number, increaseIsBad: boolean) {
  const difference = current - previous;
  const percentChange = difference / previous;

  let direction: 'up' | 'down' | 'flat';
  if (percentChange > 0.01) {
    direction = 'up';
  } else if (percentChange < -0.01) {
    direction = 'down';
  } else {
    direction = 'flat';
  }

  let sentiment: 'good' | 'bad' | 'flat';
  if (increaseIsBad) {
    sentiment = direction === 'up' ? 'bad' : direction === 'down' ? 'good' : 'flat';
  } else {
    sentiment = direction === 'up' ? 'good' : direction === 'down' ? 'bad' : 'flat';
  }

  return {
    direction,
    value: Math.abs(percentChange),
    sentiment,
  };
}

export interface CategoryData {
  currentPassCount: number;
  currentTotalCount: number;
  currentFailCount: number;
  historicalPassCount: number;
  historicalTotalCount: number;
  historicalFailCount: number;
  severity: Severity;
  historicalChange: number;
}

export function processCategoryData(evals: DashboardEvalDTO[]): Record<string, CategoryData> {
  if (evals.length === 0) {
    return {};
  }

  const categoryData: Record<string, CategoryData> = {};

  Object.entries(riskCategories).forEach(([category, subCategories]) => {
    subCategories.forEach((subCategory) => {
      const pluginId = subCategory;

      // Split evals into historical and current
      const currentEvals = evals[0];
      const historicalEvals = evals[1];

      // Calculate counts for current data
      const currentPassCount = currentEvals.pluginPassCount?.[pluginId] || 0;
      const currentFailCount = currentEvals.pluginFailCount?.[pluginId] || 0;
      const currentTotalCount = currentPassCount + currentFailCount;

      // Calculate counts for historical data
      const historicalPassCount = historicalEvals?.pluginPassCount?.[pluginId] || 0;
      const historicalFailCount = historicalEvals?.pluginFailCount?.[pluginId] || 0;
      const historicalTotalCount = historicalPassCount + historicalFailCount;

      const severity = riskCategorySeverityMap[subCategory] || Severity.Low;

      // Calculate historical change
      const historicalChange =
        historicalFailCount > 0
          ? (currentFailCount - historicalFailCount) / historicalFailCount
          : 0;

      categoryData[subCategory] = {
        currentPassCount,
        currentTotalCount,
        currentFailCount,
        historicalPassCount,
        historicalTotalCount,
        historicalFailCount,
        severity,
        historicalChange,
      };
    });
  });

  return categoryData;
}
