import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { postIssueComment } from '@cloud-ui/utils/api/issues';
import { Box, Typography, TextField, Button } from '@mui/material';
import type { IssueHistoryDTO } from '@shared/dto';
import type { IssueDTO } from '@shared/dto';
import HistoryItem from './HistoryItem';

interface HistoryTabProps {
  issue: IssueDTO;
}

export default function HistoryTab({ issue }: HistoryTabProps) {
  const [comment, setComment] = useState('');
  const queryClient = useQueryClient();

  const commentMutation = useMutation({
    mutationFn: (newComment: string) => postIssueComment(issue.id, newComment),
    onSuccess: (newCommentData: IssueHistoryDTO) => {
      // Update the issue in the issues list cache
      queryClient.setQueryData(['issues'], (oldIssues: IssueDTO[] | undefined) => {
        if (!oldIssues) {
          return oldIssues;
        }
        const updatedIssues = oldIssues.map((i) =>
          i.id === issue.id ? { ...i, history: [...i.history, newCommentData] } : i,
        );

        return updatedIssues;
      });

      setComment('');
    },
    onError: (error) => {
      console.error('Error adding comment:', error);
    },
  });

  const handleCommentSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (comment.trim()) {
      commentMutation.mutate(comment);
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        History
      </Typography>
      <form onSubmit={handleCommentSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Add a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{ mb: 1 }}
        />
        <Button type="submit" variant="contained" disabled={commentMutation.isPending}>
          {commentMutation.isPending ? 'Submitting...' : 'Add Comment'}
        </Button>
        {commentMutation.isError && (
          <Typography color="error">
            Error adding comment: {commentMutation.error.message}
          </Typography>
        )}
      </form>
      <Box sx={{ mt: 2 }}>
        {issue.history
          .slice()
          .reverse()
          .map((historyItem: IssueHistoryDTO, index: number) => (
            <HistoryItem key={index} item={historyItem} />
          ))}
      </Box>
    </Box>
  );
}
