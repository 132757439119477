import { IssueStatus, type Severity } from '@shared/constants';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface IssueFilters {
  target: string;
  severity: Severity | '';
  weakness: string;
  status: IssueStatus | '';
  riskCategory: string;
}

interface IssueFiltersStore {
  filters: IssueFilters;
  setFilter: (name: keyof IssueFilters, value: string) => void;
  clearFilters: () => void;
}

const defaultFilters: IssueFilters = {
  target: '',
  severity: '',
  weakness: '',
  status: IssueStatus.OPEN,
  riskCategory: '',
};

export const useIssueFiltersStore = create<IssueFiltersStore>()(
  persist(
    (set) => ({
      filters: defaultFilters,
      setFilter: (name, value) =>
        set((state) => ({
          filters: { ...state.filters, [name]: value },
        })),
      clearFilters: () => set({ filters: defaultFilters }),
    }),
    {
      name: 'issue-list-filters',
    },
  ),
);
