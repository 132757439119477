import React, { useState, useEffect } from 'react';
import { callApi } from '@cloud-ui/utils/api';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Container,
  Box,
  IconButton,
} from '@mui/material';

interface User {
  id: string;
  name: string;
  email: string;
  status: 'Active' | 'Pending';
}

export default function OrganizationDetails({ orgId }: { orgId: string }) {
  const [organization, setOrganization] = useState<{
    name: string;
  } | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [error, setError] = useState<string | null>(null);

  const fetchOrganizationDetails = async () => {
    try {
      const response = await callApi(`/organizations/${orgId}`);
      const data = await response.json();
      setOrganization(data);
      const userResponse = await callApi(`/organizations/${orgId}/users`);
      const userData = await userResponse.json();
      setUsers(userData);
    } catch (error) {
      console.error('Error fetching organization details:', error);
    }
  };

  useEffect(() => {
    fetchOrganizationDetails();
  }, [orgId]);

  const removeUser = async (userId: string) => {
    try {
      await callApi(`/organizations/${orgId}/users/${userId}`, {
        method: 'DELETE',
      });
      fetchOrganizationDetails();
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  const addUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      const result = await callApi(`/organizations/${orgId}/users`, {
        method: 'POST',
        body: JSON.stringify({ name: newUserName, email: newUserEmail }),
      });
      if (result.status > 299) {
        throw new Error('Failed to add user. Please try again.');
      }
      setNewUserName('');
      setNewUserEmail('');
      fetchOrganizationDetails();
    } catch (error) {
      console.error('Error adding user:', error);
      setError('Failed to add user. Please try again.');
    }
  };

  if (!organization) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 4, mt: 4, bgcolor: 'background.paper' }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {organization.name}
        </Typography>

        <Typography variant="h5" sx={{ mb: 2 }}>
          Users
        </Typography>

        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell align="right">
                    <IconButton edge="end" aria-label="delete" onClick={() => removeUser(user.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h5" sx={{ mb: 2 }}>
          Invite New User
        </Typography>

        <Box
          component="form"
          onSubmit={addUser}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Name"
            value={newUserName}
            onChange={(e) => setNewUserName(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Email"
            type="email"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
            required
            fullWidth
          />
          {error && (
            <Typography color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            startIcon={<PersonAddIcon />}
            sx={{ alignSelf: 'flex-start' }}
          >
            Invite User
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
