import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchIssues } from '@cloud-ui/utils/api/issues';
import { Severity } from '@shared/constants';
import Issues from './components/Issues';

interface IssueFilters {
  targetId: string | null;
  severity: string | null;
  weakness: string | null;
  riskCategory: string | null;
  status: string | null;
}

export default function IssuesPage() {
  const { issueId } = useParams<{ issueId?: string }>();
  const [searchParams] = useSearchParams();

  let severity = searchParams.get('severity');
  if (severity && !Object.values(Severity).includes(severity as Severity)) {
    console.error('Invalid severity', severity);
    severity = null;
  }

  const filters: IssueFilters = {
    targetId: searchParams.get('targetId'),
    severity,
    weakness: searchParams.get('weakness'),
    riskCategory: searchParams.get('riskCategory'),
    status: searchParams.get('status'),
  };

  const {
    data: issues,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['issues'],
    queryFn: fetchIssues,
  });

  return (
    <Issues
      issues={issues || []}
      isLoading={isLoading}
      error={error?.message || ''}
      selectedIssueId={issueId}
      filters={filters}
    />
  );
}
