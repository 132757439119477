import React, { useState, useContext } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import DarkMode from '@app/components/DarkMode';
import InfoModal from '@app/components/InfoModal';
import LoggedInAs from '@cloud-ui/components/LoggedInAs';
import Logo from '@cloud-ui/components/Logo';
import { ROUTES } from '@cloud-ui/constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import type { ButtonProps } from '@mui/material';
import { AppBar, Toolbar, Button, IconButton, Menu, MenuItem, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AuthContext } from '../contexts/AuthContext';

const NavButton = styled(Button)<ButtonProps & RouterLinkProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.active': {
    backgroundColor: theme.palette.action.selected,
  },
}));

function NavLink({ href, label }: { href: string; label: string }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(href);

  return (
    <NavButton component={RouterLink} to={href} className={isActive ? 'active' : ''}>
      {label}
    </NavButton>
  );
}

function RedteamDropdown() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActive = Object.values(ROUTES.redteam).some((route) =>
    location.pathname.startsWith(route),
  );

  return (
    <>
      <NavButton
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        className={isActive ? 'active' : ''}
        to="#"
      >
        Redteam
      </NavButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        <MenuItem
          key={ROUTES.redteam.dashboard}
          onClick={handleClose}
          component={RouterLink}
          to={ROUTES.redteam.dashboard}
          selected={location.pathname.startsWith(ROUTES.redteam.dashboard)}
        >
          Dashboard
        </MenuItem>
        <MenuItem
          key={ROUTES.redteam.vulnerabilities}
          onClick={handleClose}
          component={RouterLink}
          to={ROUTES.redteam.vulnerabilities}
          selected={location.pathname.startsWith(ROUTES.redteam.vulnerabilities)}
        >
          Vulnerabilities
        </MenuItem>
      </Menu>
    </>
  );
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[1],
  marginBottom: theme.spacing(2),
}));

const NavToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
});

const NavSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
});

export default function Navigation({
  darkMode,
  onToggleDarkMode,
}: {
  darkMode: boolean;
  onToggleDarkMode: () => void;
}) {
  const { user } = useContext(AuthContext) || {};
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const handleModalToggle = () => setShowInfoModal((prevState) => !prevState);

  return (
    <>
      <StyledAppBar position="static" elevation={0}>
        <NavToolbar>
          <NavSection>
            <Logo />
            {user && (
              <>
                <NavLink href={ROUTES.eval} label="Evals" />
                <RedteamDropdown />
              </>
            )}
          </NavSection>
          <NavSection>
            <Tooltip title="Info">
              <IconButton onClick={handleModalToggle} color="inherit">
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <DarkMode onToggleDarkMode={onToggleDarkMode} />
            <LoggedInAs />
          </NavSection>
        </NavToolbar>
      </StyledAppBar>
      <InfoModal open={showInfoModal} onClose={handleModalToggle} />
    </>
  );
}
