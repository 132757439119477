import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ListItem, Typography, Box, useTheme } from '@mui/material';
import type { SeverityLevelType, IssueStatusType } from '@shared/constants';
import type { IssueDTO } from '@shared/dto';
import { formatDistanceToNow } from 'date-fns';
import { severityColors, statusColors } from './Issues';

interface IssueItemProps {
  issue: IssueDTO;
  isCompact: boolean;
  isSelected?: boolean;
}

export default function IssueItem({ issue, isCompact, isSelected }: IssueItemProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const openDuration = formatDistanceToNow(new Date(issue.createdAt), { addSuffix: true });
  const handleClick = () => {
    navigate(`${ROUTES.redteam.vulnerabilities}/${issue.id}`);
  };
  const darkMode = theme.palette.mode === 'dark';
  return (
    <ListItem
      onClick={handleClick}
      sx={{
        p: 0,
        mb: 1,
        width: '100%',
        borderBottom: '1px solid #e0e0e0',
        backgroundColor: isSelected ? (darkMode ? '#005ea8' : '#b5deff') : 'inherit',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: isSelected
            ? darkMode
              ? '#005ea8'
              : '#b5deff'
            : theme.palette.action.hover,
        },
        color: 'inherit',
      }}
    >
      <Box
        sx={{
          px: 2,
          py: isCompact ? 1 : 2,
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <ErrorOutlineIcon
          sx={{ mr: 2, mt: 0.5, color: severityColors[issue.severity as SeverityLevelType] }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant={isCompact ? 'body1' : 'subtitle1'} sx={{ fontWeight: 'bold' }}>
            {issue.weakness}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, flexWrap: 'wrap' }}>
            <Typography variant="body2" color="textSecondary">
              Status:{' '}
              <span style={{ color: statusColors[issue.status as IssueStatusType] }}>
                {issue.status}
              </span>{' '}
              | Severity:{' '}
              <span style={{ color: severityColors[issue.severity as SeverityLevelType] }}>
                {issue.severity}
              </span>
            </Typography>
            {!isCompact && (
              <Typography variant="body2" color="textSecondary">
                {issue.targetId} • {issue.occurrences} occurrences • Opened {openDuration}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}
