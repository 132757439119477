import React from 'react';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Grid2 as Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  categoryAliases,
  displayNameOverrides,
  subCategoryDescriptions,
} from '@promptfoo/redteam/constants';
import type { DashboardEvalDTO } from '@shared/dto';
import { processCategoryData, calculateTrend } from './utils';

interface EmergingRisksProps {
  evals: DashboardEvalDTO[];
  navigateToIssues: (params: Record<string, string>) => void;
}

const EmergingRisks: React.FC<EmergingRisksProps> = ({ evals, navigateToIssues }) => {
  const categoryData = processCategoryData(evals);

  let emergingRisks = Object.entries(categoryData)
    .filter(
      ([, data]) =>
        data.currentTotalCount > 0 &&
        data.historicalTotalCount >= 0 &&
        data.historicalPassCount > data.currentPassCount,
    )
    .sort(([, a], [, b]) => b.historicalChange - a.historicalChange)
    .slice(0, 10);

  if (emergingRisks.length === 0) {
    emergingRisks = Object.entries(categoryData)
      .filter(([, data]) => data.currentFailCount > 0 && data.historicalFailCount >= 0)
      .sort(([categoryA], [categoryB]) => {
        const dataA = categoryData[categoryA];
        const dataB = categoryData[categoryB];
        return dataB.historicalChange - dataA.historicalChange;
      })
      .slice(0, 5);
  }

  return (
    <Grid size={{ xs: 12 }}>
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
          Trending Risks
        </Typography>
        {emergingRisks.length > 0 ? (
          <Box sx={{ width: '100%', display: 'flex', flexWrap: 'nowrap', gap: 2 }}>
            {emergingRisks.map(([category, data], index) => {
              const displayName =
                displayNameOverrides[category as keyof typeof displayNameOverrides] ||
                categoryAliases[category as keyof typeof categoryAliases] ||
                category;
              const trend = calculateTrend(
                data.currentFailCount / data.currentTotalCount,
                data.historicalFailCount / data.historicalTotalCount,
                true /* more is bad */,
              );
              return (
                <Box
                  key={index}
                  sx={{
                    flexGrow: 1,
                    flexBasis: '0',
                    minWidth: '200px',
                    maxWidth: '300px',
                    p: 2,
                    borderRadius: 2,
                    bgcolor: '#f8f8f8',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigateToIssues({ weakness: category })}
                >
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box display="flex" alignItems="center" mb={1}>
                      <Tooltip
                        title={
                          subCategoryDescriptions[category as keyof typeof subCategoryDescriptions]
                        }
                        arrow
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            flexGrow: 1,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {displayName}
                        </Typography>
                      </Tooltip>
                      <Tooltip title="Increasing failure rate" arrow>
                        <TrendingUpIcon color="error" />
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="body2">
                        Recent:{' '}
                        {((data.currentFailCount / data.currentTotalCount) * 100).toFixed(1)}%
                        Successful attacks
                      </Typography>
                      <Typography variant="body2">
                        {data.historicalFailCount && data.historicalTotalCount
                          ? ` Historical: ${((data.historicalFailCount / data.historicalTotalCount) * 100).toFixed(1)}% passed probes`
                          : ''}
                      </Typography>
                      {trend.value === Infinity || Number.isNaN(trend.value) ? (
                        <Typography variant="body2" color="error">
                          New failures
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          color={trend.sentiment === 'bad' ? 'error' : 'success'}
                        >
                          {trend.value.toLocaleString(undefined, {
                            style: 'percent',
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          })}{' '}
                          {trend.direction === 'up' ? 'increase' : 'decrease'} in attack success
                          rate
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography variant="body2">No trending risks to report</Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default EmergingRisks;
