import type {
  IssuesOverTimeDTO,
  DashboardEvalDTO,
  DashboardIssuesDTO,
  DashboardTargetsDTO,
} from '@shared/dto';
import { callApi } from '../api';

export const getIssueCountsBySeverity = async (
  targetId: string | null,
): Promise<DashboardIssuesDTO> => {
  const response = await callApi(
    `/dashboard/issues${targetId ? `?targetId=${encodeURIComponent(targetId)}` : ''}`,
  );
  return await response.json();
};

export const getTargets = async (): Promise<DashboardTargetsDTO> => {
  const response = await callApi('/dashboard/targets');
  return await response.json();
};

export const getOpenIssuesPerDay = async (targetId: string | null): Promise<IssuesOverTimeDTO> => {
  if (!targetId) {
    return [];
  }
  const response = await callApi(
    `/dashboard/issuesPerDay?targetId=${encodeURIComponent(targetId)}`,
  );
  return await response.json();
};

export const getEvals = async (targetId: string | null): Promise<DashboardEvalDTO[]> => {
  const response = await callApi(
    `/dashboard/evals?limit=20${targetId ? `&targetId=${encodeURIComponent(targetId)}` : ''}`,
  );
  return await response.json();
};
