import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import LoadingSpinner from '@cloud-ui/pages/redteam/issues/components/LoadingSpinner';
import Issue from '@cloud-ui/pages/redteam/issues/components/issue/Issue';
import { getIssueFromEvalResult } from '@cloud-ui/utils/api/evals';
import { updateIssue } from '@cloud-ui/utils/api/issues';
import { Dialog } from '@mui/material';
import type { IssueDTO } from '@shared/dto';

export default function IssueDialog({
  open,
  onClose,
  evalResultId,
}: {
  open: boolean;
  onClose: () => void;

  evalResultId: string;
}) {
  const [issue, setIssue] = useState<IssueDTO | null>(null);

  const { showToast } = useToast();
  const {
    data: issueQueryResult,
    isPending,
    isError,
  } = useQuery({
    queryKey: ['issue-by-eval-result', evalResultId],
    queryFn: () => getIssueFromEvalResult(evalResultId),
    enabled: open,
  });

  useEffect(() => {
    if (issueQueryResult) {
      setIssue(issueQueryResult);
    }
  }, [issueQueryResult]);

  const updateIssueMutation = useMutation({
    mutationFn: (changes: Partial<IssueDTO>) =>
      issue ? updateIssue(issue.id, changes) : Promise.reject('No issue selected'),
    onSuccess: (updatedIssue) => {
      setIssue(updatedIssue);
    },
    onError: (error) => {
      showToast('Error updating issue', 'error');
      console.error('Error updating issue:', error);
    },
  });

  const handleIssueUpdate = useCallback(
    (changes: Partial<IssueDTO>) => {
      if (issue) {
        updateIssueMutation.mutate(changes);
      }
    },
    [issue, updateIssueMutation],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      {isPending && (
        <div className="flex justify-center p-4">
          <LoadingSpinner />
          <span className="ml-2">Loading issue details...</span>
        </div>
      )}
      {isError && <div className="p-4 text-red-500">Failed to load issue details</div>}
      {!isPending && !isError && issue == null && (
        <div className="p-4 text-gray-500">No issue found for this test case</div>
      )}
      {issue && (
        <Issue
          issue={issue}
          onClose={onClose}
          onIssueUpdate={handleIssueUpdate}
          isUpdating={updateIssueMutation.isPending}
        />
      )}
    </Dialog>
  );
}
