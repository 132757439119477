import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import EvalOutputPromptDialog from '@cloud-ui/pages/eval/components/EvalOutputPromptDialog';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import type { EvalResultDTO } from '@shared/dto';

interface TestCasesTabProps {
  evalResults: EvalResultDTO[];
  isEvalResultsLoading: boolean;
  isEvalResultsError: boolean;
}

export default function TestCasesTab({
  evalResults,
  isEvalResultsLoading,
  isEvalResultsError,
}: TestCasesTabProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResult, setSelectedResult] = useState<any | null>(null);

  const handleOpenDialog = (result: any) => {
    setSelectedResult(result);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedResult(null);
  };

  const formatContent = (content: any): string => {
    if (typeof content === 'object') {
      return JSON.stringify(content, null, 2);
    }
    if (typeof content === 'string') {
      try {
        // Attempt to parse and re-stringify if it's a JSON string
        return JSON.stringify(JSON.parse(content), null, 2);
      } catch {
        // If it's not valid JSON, return the original string
        return content;
      }
    }
    return String(content);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  return (
    <ErrorBoundary fallbackRender={({ error }) => <Typography>{error.message}</Typography>}>
      <Box>
        <Typography variant="h6" gutterBottom>
          Attacks ({evalResults?.length || 0})
        </Typography>
        {isEvalResultsLoading ? (
          <Typography>Loading evaluation results...</Typography>
        ) : isEvalResultsError ? (
          <Typography color="error">Error loading evaluation results</Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date/Time</TableCell>
                    <TableCell>Attack</TableCell>
                    <TableCell style={{ width: '30%' }}>Result</TableCell>
                    <TableCell>Strategy</TableCell>
                    <TableCell>Eval</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {evalResults?.map((result) => (
                    <TableRow key={result.id}>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        {new Date(result.createdAt).toLocaleString()}
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        {Object.entries(result.testCase.vars || {}).map(([key, value]) => (
                          <Typography key={key} variant="body2" sx={{ display: 'block', mb: 1 }}>
                            <strong>{key}:</strong> {value as string}
                          </Typography>
                        ))}
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top', maxWidth: 0 }}>
                        <Tooltip
                          title={
                            <pre
                              style={{
                                whiteSpace: 'pre-wrap',
                                fontSize: '0.8rem',
                              }}
                            >
                              {formatContent(result.response?.output)}
                            </pre>
                          }
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: 'pre-wrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {truncateText(formatContent(result.response?.output), 300)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        {result.testCase.metadata?.strategyId || 'Basic'}
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        <RouterLink to={`${ROUTES.eval}/${result.evalId}`}>
                          {result.evalId}
                        </RouterLink>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        <SearchIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleOpenDialog(result)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <EvalOutputPromptDialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              prompt={selectedResult?.prompt.raw || ''}
              output={
                typeof selectedResult?.response.output === 'object'
                  ? JSON.stringify(selectedResult?.response.output, null, 2)
                  : selectedResult?.response.output || ''
              }
              gradingResults={selectedResult?.gradingResult?.componentResults || []}
              metadata={selectedResult?.metadata || {}}
            />
          </>
        )}
      </Box>
    </ErrorBoundary>
  );
}
