import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { type Plugin, riskCategorySeverityMap, Severity } from '@promptfoo/redteam/constants';
import type { DashboardEvalDTO, IssuesOverTimeDTO } from '@shared/dto';
import AttackSuccessRateChart, { type AttackSuccessRateDataPoint } from './AttackSuccessRateChart';
import IssuesOverTimeChart from './IssuesOverTimeChart';

interface DashboardChartsProps {
  // attackSuccessRateData: AttackSuccessRateDataPoint[];
  evals: DashboardEvalDTO[];
  issuesOverTimeData: IssuesOverTimeDTO;
}

function generateAttackSuccessRateOverTime(
  evals: DashboardEvalDTO[],
): AttackSuccessRateDataPoint[] {
  return evals
    ?.sort((a, b) => a.createdAt - b.createdAt)
    ?.map((eval_) => {
      const dataPoint: AttackSuccessRateDataPoint = {
        evalId: eval_.evalId || '',
        date: new Date(eval_.createdAt).toLocaleString(),
        [Severity.Critical]: 0,
        [Severity.High]: 0,
        [Severity.Medium]: 0,
        [Severity.Low]: 0,
        total: 0,
      };

      Object.entries(eval_.pluginFailCount || {}).forEach(([pluginId, count]) => {
        const severity = riskCategorySeverityMap[pluginId as Plugin] || Severity.Low;
        dataPoint[severity] += count;
        dataPoint.total += count;
      });

      return dataPoint;
    });
}

export default function DashboardCharts({ evals, issuesOverTimeData }: DashboardChartsProps) {
  const [activeChart, setActiveChart] = useState<'attackSuccess' | 'openIssues'>('attackSuccess');

  const attackSuccessRateData = useMemo(() => {
    return generateAttackSuccessRateOverTime(evals);
  }, [evals]);

  const memoizedAttackSuccessChart = useMemo(
    () => <AttackSuccessRateChart data={attackSuccessRateData} />,
    [attackSuccessRateData],
  );

  const memoizedIssuesOverTimeChart = useMemo(
    () => <IssuesOverTimeChart data={issuesOverTimeData} />,
    [issuesOverTimeData],
  );

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: 350,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h6">
          {activeChart === 'attackSuccess'
            ? 'Attack Success'
            : activeChart === 'openIssues'
              ? 'Open Vulnerabilities'
              : 'Successful Attacks by Application'}
        </Typography>
        <ToggleButtonGroup
          value={activeChart}
          exclusive
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setActiveChart(newValue);
            }
          }}
          size="small"
        >
          <ToggleButton value="attackSuccess">Attack Success</ToggleButton>
          <ToggleButton value="openIssues">Open Vulnerabilities</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {activeChart === 'attackSuccess' ? (
        memoizedAttackSuccessChart
      ) : activeChart === 'openIssues' ? (
        memoizedIssuesOverTimeChart
      ) : (
        <></>
      )}
    </Paper>
  );
}
