import type { EvalResultDTO, IssueDTO } from '@shared/dto';
import { callApi } from '../api';

export const getIssueEvalResults = async (issueId: string): Promise<EvalResultDTO[]> => {
  const response = await callApi(`/issues/${issueId}/evalResults`);
  return await response.json();
};

export const getIssue = async (issueId: string): Promise<IssueDTO> => {
  const response = await callApi(`/issues/${issueId}`);
  return await response.json();
};

export const updateIssue = async (id: string, changes: Partial<IssueDTO>) => {
  const response = await callApi(`/issues/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(changes),
  });
  return await response.json();
};

export async function fetchIssues(): Promise<IssueDTO[]> {
  const response = await callApi('/issues');
  return await response.json();
}

export const postIssueComment = async (issueId: string, comment: string) => {
  const response = await callApi(`/issues/${issueId}/comment`, {
    method: 'POST',
    body: JSON.stringify({ comment }),
  });
  return await response.json();
};

export const getIssueRemediation = async (issueId: string) => {
  const response = await callApi(`/issues/${issueId}/remediation`);
  return await response.json();
};
