import { Severity } from '@promptfoo/redteam/constants';

export enum IssueStatus {
  OPEN = 'open',
  IGNORED = 'ignored',
  FALSE_POSITIVE = 'false_positive',
  FIXED = 'fixed',
}
export type IssueStatusType = (typeof IssueStatus)[keyof typeof IssueStatus];
export { Severity };
export type SeverityLevelType = (typeof Severity)[keyof typeof Severity];

export const SeverityOrder = [Severity.Low, Severity.Medium, Severity.High, Severity.Critical];
