import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@cloud-ui/contexts/AuthContext';
import { callApi } from '@cloud-ui/utils/api';
import { Paper, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Login() {
  const theme = useTheme();
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { user } = useContext(AuthContext) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  const handleSignIn = async (event: React.FormEvent) => {
    setLoading(true);
    setError('');
    event.preventDefault();

    try {
      const response = await callApi('/users/login', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        setEmailSent(true);
      } else {
        setError(data.message || 'An error occurred during sign in');
      }
    } catch (err) {
      console.error('Error during login:', err);
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email: string) => {
    // Basic email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {emailSent ? (
          <Box mt={2}>
            <Typography>
              Email sent to {email}. Check your inbox and click the link to login.
            </Typography>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSignIn} mt={2}>
            <TextField
              disabled={loading}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
              value={email}
              error={Boolean(error)}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              disabled={loading || !isValidEmail(email)}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 2 }}
            >
              Send me a magic link
            </Button>
            <Typography mt={2}>
              Don't have an account yet?{' '}
              <Button
                href="mailto:sales@promptfoo.dev"
                color="primary"
                sx={{ p: 0, textTransform: 'none' }}
              >
                Contact us
              </Button>
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
}
