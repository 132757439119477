export const ROUTES = {
  eval: '/eval',
  login: '/login',
  organization: '/organizations',
  redteam: {
    dashboard: '/redteam/dashboard',
    report: '/redteam/report',
    vulnerabilities: '/redteam/vulnerabilities',
  },
};

export const EVAL_ID_SCAN_PATTERN = /\s+from scan:\s+(eval-[\w-:.]+)$/;
