import React, { useState } from 'react';
import { getAuthToken } from '@cloud-ui/utils/api';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Typography, Paper, useTheme, IconButton, Tooltip } from '@mui/material';

export const EmptyState: React.FC = () => {
  const theme = useTheme();
  const apiKey = getAuthToken() || '';
  const [copiedLoginCommand, setCopiedLoginCommand] = useState(false);
  const apiHost = import.meta.env.VITE_API_BASE_URL;

  const loginCommand = `promptfoo auth login --host ${apiHost} --api-key ${apiKey}`;

  const handleCopy = (text: string, setCopied: React.Dispatch<React.SetStateAction<boolean>>) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const CommandBox = ({
    command,
    copied,
    setCopied,
  }: {
    command: string;
    copied: boolean;
    setCopied: React.Dispatch<React.SetStateAction<boolean>>;
  }) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: 1,
        p: 2,
        bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
        borderRadius: 1,
      }}
    >
      <Typography
        component="code"
        sx={{
          flexGrow: 1,
          color: 'text.primary',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {command}
      </Typography>
      <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
        <IconButton
          size="small"
          onClick={() => handleCopy(command, setCopied)}
          sx={{
            ml: 1,
            color: theme.palette.text.secondary,
          }}
        >
          {copied ? <CheckIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <Paper
        elevation={3}
        sx={{
          p: 4,
          textAlign: 'center',
          maxWidth: 500,
          bgcolor: 'background.paper',
        }}
      >
        <AssessmentIcon sx={{ fontSize: 60, mb: 2, color: 'primary.main' }} />
        <Typography variant="h5" gutterBottom>
          Welcome to Promptfoo
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1" color="text.secondary">
            To get started, log in with Promptfoo on the command line and then share an eval:
          </Typography>
          <CommandBox
            command={loginCommand}
            copied={copiedLoginCommand}
            setCopied={setCopiedLoginCommand}
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" color="text.secondary">
            Then share your latest eval:
          </Typography>
          <Box
            component="code"
            sx={{
              display: 'block',
              mt: 1,
              p: 2,
              bgcolor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
              borderRadius: 1,
              color: 'text.primary',
            }}
          >
            promptfoo share
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" color="text.secondary">
            For more details, see{' '}
            <a href="https://www.promptfoo.dev/docs/cloud/">Cloud Documentation</a>.
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default EmptyState;
