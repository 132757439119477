import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Paper, Typography, CircularProgress, Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, useMediaQuery } from '@mui/system';
import type { DashboardEvalDTO } from '@shared/dto';
import NewIssuesList from './NewIssuesList';
import SolvedIssues from './SolvedIssues';

export default function LatestScanSection({
  evals,
  navigateToIssues,
}: {
  evals: DashboardEvalDTO[];
  navigateToIssues: (params: Record<string, string>) => void;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [currentEvalIndex, setCurrentEvalIndex] = useState(0);
  const latestEval = evals[currentEvalIndex];
  const latestEvalFailCount = Object.values(latestEval?.pluginFailCount ?? {}).reduce(
    (acc, count) => acc + count,
    0,
  );
  const latestEvalPassCount = Object.values(latestEval?.pluginPassCount ?? {}).reduce(
    (acc, count) => acc + count,
    0,
  );

  const handlePrevEval = () => {
    if (currentEvalIndex < evals.length - 1) {
      setCurrentEvalIndex(currentEvalIndex + 1);
    }
  };

  const handleNextEval = () => {
    if (currentEvalIndex > 0) {
      setCurrentEvalIndex(currentEvalIndex - 1);
    }
  };

  return (
    <Grid size={{ xs: 12 }}>
      <Paper sx={{ p: 3 }}>
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            bgcolor: 'grey.200',
            mx: -3,
            mt: -3,
            p: 3,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            {/* Navigation controls */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
              <IconButton onClick={handlePrevEval} disabled={currentEvalIndex >= evals.length - 1}>
                <ChevronLeftIcon />
              </IconButton>
              <Box>
                <Typography
                  variant="h6"
                  align="center"
                  onClick={() => navigate(`${ROUTES.eval}/${latestEval?.evalId}`)}
                  sx={{ cursor: 'pointer' }}
                >
                  {currentEvalIndex === 0 ? 'Latest Scan' : 'Scan'}
                </Typography>
                <Typography variant="body2" align="center">
                  {latestEval?.createdAt ? new Date(latestEval.createdAt).toLocaleString() : 'TBD'}
                </Typography>
              </Box>
              <IconButton onClick={handleNextEval} disabled={currentEvalIndex <= 0}>
                <ChevronRightIcon />
              </IconButton>
            </Box>

            {/* Navigation indicators */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ display: 'flex', gap: 0.5 }}>
                {[...Array(evals.length)].reverse().map((_, index) => {
                  const evalIndex = evals.length - 1 - index;
                  return (
                    <Box
                      key={evalIndex}
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        bgcolor: evalIndex === currentEvalIndex ? 'primary.main' : 'grey.400',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          transform: 'scale(1.2)',
                        },
                      }}
                      onClick={() => setCurrentEvalIndex(evalIndex)}
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Content */}
        <Grid container spacing={3}>
          {/* Attack Success Rate Gauge */}
          {latestEval ? (
            <>
              <Grid
                size={{ xs: 12, md: 4 }}
                onClick={() => navigate(`${ROUTES.eval}/${latestEval?.evalId}`)}
                sx={{ cursor: 'pointer' }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h6" align="center">
                    Attack Success Rate
                  </Typography>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '200px',
                      overflow: 'visible',
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={100}
                      size={160}
                      thickness={4}
                      sx={{
                        color: theme.palette.grey[200],
                        position: 'absolute',
                      }}
                    />
                    <CircularProgress
                      variant="determinate"
                      value={
                        latestEvalFailCount + latestEvalPassCount > 0
                          ? (latestEvalFailCount / (latestEvalFailCount + latestEvalPassCount)) *
                            100
                          : 0
                      }
                      size={160}
                      thickness={4}
                      sx={{
                        color: theme.palette.error.main,
                        position: 'absolute',
                      }}
                    />
                    <Typography
                      variant="h4"
                      sx={{
                        position: 'absolute',
                      }}
                    >
                      {`${Math.round((latestEvalFailCount / (latestEvalFailCount + latestEvalPassCount)) * 100)}%`}
                    </Typography>
                  </Box>
                  <Typography variant="body2" align="center">
                    {latestEvalFailCount} of {latestEvalPassCount + latestEvalFailCount} Attacks
                    Succeeded
                  </Typography>
                </Box>
              </Grid>

              {/* New/Solved Issues */}
              <Grid size={{ xs: 12, md: 8 }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 2, md: 0 },
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <NewIssuesList
                      evals={evals}
                      navigateToIssues={navigateToIssues}
                      currentEvalIndex={currentEvalIndex}
                    />
                  </Box>
                  <Divider
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    flexItem
                    sx={{
                      mx: { xs: 0, md: 2 },
                      my: { xs: 2, md: 0 },
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <SolvedIssues
                      evals={evals}
                      navigateToIssues={navigateToIssues}
                      currentEvalIndex={currentEvalIndex}
                    />
                  </Box>
                </Box>
              </Grid>
            </>
          ) : (
            <Typography>Run your first scan to see the latest scan results</Typography>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
}
