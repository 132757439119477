import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  type SelectChangeEvent,
  Autocomplete,
  Button,
} from '@mui/material';
import { riskCategories, type TopLevelCategory } from '@promptfoo/redteam/constants';
import { Severity, IssueStatus } from '@shared/constants';

interface FilterBarProps {
  filters: {
    target: string;
    severity: string;
    weakness: string;
    status: string;
    riskCategory: string;
  };
  uniqueTargets: string[];
  uniqueVulnerabilities: string[];
  onFilterChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => void;
  onClearFilters: () => void;
}

export default function FilterBar({
  filters,
  uniqueTargets,
  uniqueVulnerabilities,
  onFilterChange,
  onClearFilters,
}: FilterBarProps) {
  return (
    <Box
      sx={{
        mb: 2,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        pr: 2, // Add right padding
      }}
    >
      <FormControl variant="outlined" size="small" sx={{ minWidth: 250, maxWidth: 250 }}>
        <InputLabel>Target</InputLabel>
        <Select
          name="target"
          value={filters.target}
          onChange={onFilterChange as (event: SelectChangeEvent<string>) => void}
          label="Target"
        >
          <MenuItem value="">All</MenuItem>
          {uniqueTargets.map((target, index) => (
            <MenuItem key={index} value={target}>
              {target}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 150, maxWidth: 150 }}>
        <InputLabel>Severity</InputLabel>
        <Select
          name="severity"
          value={filters.severity}
          onChange={onFilterChange as (event: SelectChangeEvent<string>) => void}
          label="Severity"
        >
          <MenuItem value="">All</MenuItem>
          {Object.values(Severity).map((level) => (
            <MenuItem key={level} value={level}>
              {level.charAt(0).toUpperCase() + level.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 150, maxWidth: 150 }}>
        <InputLabel>Status</InputLabel>
        <Select
          name="status"
          value={filters.status}
          onChange={onFilterChange as (event: SelectChangeEvent<string>) => void}
          label="Status"
        >
          <MenuItem value="">All</MenuItem>
          {Object.values(IssueStatus).map((status) => (
            <MenuItem key={status} value={status}>
              {status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
        <InputLabel>Risk Category</InputLabel>
        <Select
          name="riskCategory"
          value={filters.riskCategory}
          onChange={onFilterChange as (event: SelectChangeEvent<string>) => void}
          label="Risk Category"
        >
          <MenuItem value="">All</MenuItem>
          {(Object.keys(riskCategories) as TopLevelCategory[]).map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Autocomplete
        size="small"
        options={uniqueVulnerabilities}
        value={filters.weakness}
        freeSolo
        onChange={(_, newValue) => {
          onFilterChange({
            target: { name: 'weakness', value: newValue || '' },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="vulnerability"
            label="Vulnerability"
            variant="outlined"
            size="small"
            sx={{ minWidth: 200 }}
          />
        )}
      />

      <Button variant="contained" color="primary" onClick={onClearFilters}>
        Clear
      </Button>
    </Box>
  );
}
