import React, { useMemo } from 'react';
import { Severity } from '@shared/constants';
import type { IssuesOverTimeDTO } from '@shared/dto';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

export interface IssuesOverTimeDataPoint {
  date: string;
  [Severity.Critical]: number;
  [Severity.High]: number;
  [Severity.Medium]: number;
  [Severity.Low]: number;
  total: number;
}

interface IssuesOverTimeChartProps {
  data: IssuesOverTimeDTO;
}

export default function IssuesOverTimeChart({ data }: IssuesOverTimeChartProps) {
  const aggregatedData = useMemo(() => {
    const dataMap = new Map<string, IssuesOverTimeDataPoint>();

    data.forEach((point) => {
      if (dataMap.has(point.date)) {
        const existing = dataMap.get(point.date)!;
        dataMap.set(point.date, {
          ...existing,
          [Severity.Critical]: existing[Severity.Critical] + point.data[Severity.Critical],
          [Severity.High]: existing[Severity.High] + point.data[Severity.High],
          [Severity.Medium]: existing[Severity.Medium] + point.data[Severity.Medium],
          [Severity.Low]: existing[Severity.Low] + point.data[Severity.Low],
          total: existing.total + Object.values(point.data).reduce((acc, curr) => acc + curr, 0),
        });
      } else {
        dataMap.set(point.date, {
          date: point.date,
          [Severity.Critical]: point.data[Severity.Critical],
          [Severity.High]: point.data[Severity.High],
          [Severity.Medium]: point.data[Severity.Medium],
          [Severity.Low]: point.data[Severity.Low],
          total: Object.values(point.data).reduce((acc, curr) => acc + curr, 0),
        });
      }
    });

    return Array.from(dataMap.values()).sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
  }, [data]);

  const severityColors = {
    [Severity.Critical]: '#d32f2f',
    [Severity.High]: '#f57c00',
    [Severity.Medium]: '#fbc02d',
    [Severity.Low]: '#388e3c',
  };
  const maxTotal = aggregatedData.length
    ? Math.max(...aggregatedData.map((point) => point.total))
    : 0;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={aggregatedData}>
        <XAxis
          dataKey="date"
          tick={{ fontSize: 10 }}
          type="category"
          tickFormatter={(value) => new Date(value).toLocaleDateString()}
        />
        <YAxis
          width={40}
          domain={[0, maxTotal > 0 ? maxTotal + Math.round(maxTotal * 0.25) : 10]}
          tick={{ fontSize: 12 }}
        />
        <Tooltip
          formatter={(value: number, name: string) => {
            return [value, name === 'total' ? 'Total' : name];
          }}
        />
        <Legend />
        {Object.values(Severity).map((severity) => (
          <Area
            key={severity}
            type="monotone"
            dataKey={severity}
            stackId="1"
            stroke={severityColors[severity]}
            fill={severityColors[severity]}
            isAnimationActive={false}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}
