import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { categoryAliases, displayNameOverrides, Severity } from '@promptfoo/redteam/constants';
import type { DashboardEvalDTO } from '@shared/dto';
import { processCategoryData } from './utils';

interface WeakestAreasListProps {
  evals: DashboardEvalDTO[];
  navigateToIssues: (params: Record<string, string>) => void;
}

export default function WeakestAreasList({ evals, navigateToIssues }: WeakestAreasListProps) {
  const categoryData = evals.length > 0 ? processCategoryData([evals[0]]) : {};
  const [page, setPage] = useState(1);
  const itemsPerPage = 6; // Adjust this value as needed

  const sortedCategories = React.useMemo(() => {
    const filteredCategories = Object.entries(categoryData).filter(
      ([, data]) => data.currentFailCount > 0,
    );
    return filteredCategories.sort(
      ([, a], [, b]) =>
        b.currentFailCount / b.currentTotalCount - a.currentFailCount / a.currentTotalCount,
    );
  }, [categoryData]);

  const pageCount = Math.ceil(sortedCategories.length / itemsPerPage);
  const displayedCategories = sortedCategories.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Typography variant="h6" gutterBottom>
        Weakest Areas ({sortedCategories.length})
      </Typography>
      <List>
        {displayedCategories.map(([category, data], index) => {
          const displayName =
            displayNameOverrides[category as keyof typeof displayNameOverrides] ||
            categoryAliases[category as keyof typeof categoryAliases] ||
            category;

          const failPercentage =
            data.currentTotalCount === 0
              ? 0
              : (data.currentFailCount / data.currentTotalCount) * 100;

          const severityColor = {
            [Severity.Critical]: 'error',
            [Severity.High]: 'warning',
            [Severity.Medium]: 'info',
            [Severity.Low]: 'success',
          };

          return (
            <ListItem
              key={index}
              onClick={() => navigateToIssues({ weakness: displayName })}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'action.hover',
                  transition: 'background-color 0.3s',
                },
                borderBottom: '1px solid #e0e0e0',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <Typography variant="subtitle1">{displayName}</Typography>
                  <Chip
                    label={data.severity}
                    // @ts-expect-error
                    color={severityColor[data.severity]}
                    size="small"
                    sx={{ fontWeight: 'bold' }}
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {`${failPercentage.toFixed(1)}% failing (${data.currentFailCount}/${data.currentTotalCount} probes)`}
                </Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>
      <Pagination
        count={pageCount}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
      />
    </Box>
  );
}
