import { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import type { SeverityLevelType } from '@shared/constants';
import type { DashboardEvalDTO } from '@shared/dto';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { severityColors } from '../../issues/components/Issues';

interface IssuesBySeverityProps {
  severityCounts: Record<SeverityLevelType, number> | undefined;
  evals: DashboardEvalDTO[];
  navigateToIssues: (params: Record<string, string>) => void;
}

export default function IssuesBySeverity({
  severityCounts,
  evals,
  navigateToIssues,
}: IssuesBySeverityProps) {
  const [data, setData] = useState<{ severity: SeverityLevelType; count: number }[]>([]);

  useEffect(() => {
    const data = Object.entries(severityCounts || {}).map(([severity, count]) => ({
      severity: severity as SeverityLevelType,
      count,
    }));
    setData(data);
  }, [severityCounts]);

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: 350,
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Open Vulnerabilities by Severity
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="count"
            nameKey="severity"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            paddingAngle={4}
            isAnimationActive={false}
          >
            {data.map(({ severity, count }, index) => (
              <Cell
                key={`cell-${index}`}
                fill={severityColors[severity as SeverityLevelType]}
                stroke="none"
                onClick={() => navigateToIssues({ severity })}
              />
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name) => [`${value} attack types`, `${name}`]}
            contentStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              backdropFilter: 'blur(4px)',
              border: 'none',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          />
          <Legend
            verticalAlign="bottom"
            height={36}
            iconType="circle"
            iconSize={10}
            formatter={(value) => <span style={{ color: '#666', fontSize: '12px' }}>{value}</span>}
          />
          <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
            <tspan x="50%" dy="-1em" fontSize="24" fontWeight="bold">
              {Object.values(severityCounts || {}).reduce((a, b) => a + b, 0)}
            </tspan>
            <tspan x="50%" dy="1.2em" fontSize="14">
              total
            </tspan>
          </text>
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  );
}
