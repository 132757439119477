import { Navigate, useParams } from 'react-router-dom';
import OrganizationDetails from './components/OrganizationDetails';

export default function OrganizationPage() {
  const { orgId } = useParams<{ orgId: string }>();
  if (!orgId) {
    return <Navigate to="/" />;
  }
  return (
    <div>
      <OrganizationDetails orgId={orgId} />
    </div>
  );
}
