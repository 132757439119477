import {
  Grid2 as Grid,
  Stack,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import { Severity, severityDisplayNames } from '@promptfoo/redteam/constants';

export default function SeverityCards({
  issueCountsBySeverity,
  navigateToIssues,
}: {
  issueCountsBySeverity: Record<Severity, number> | undefined;
  navigateToIssues: (params: Record<string, string>) => void;
}) {
  if (!issueCountsBySeverity) {
    return null;
  }

  return (
    <Grid size={{ xs: 12 }}>
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
        {[Severity.Critical, Severity.High, Severity.Medium, Severity.Low].map((severity) => (
          <Box key={severity} flex={1}>
            <Card
              className={`severity-card card-${severity.toLowerCase()}`}
              sx={{
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                  '[data-theme="dark"] &': {
                    boxShadow: 'none',
                  },
                },
                borderLeft: '5px solid',
                borderLeftColor: {
                  critical: '#ff1744',
                  high: '#ff9100',
                  medium: '#ffc400',
                  low: '#00e676',
                }[severity.toLowerCase()],
              }}
            >
              <CardActionArea
                onClick={() => {
                  navigateToIssues({ severity });
                }}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {severityDisplayNames[severity]}
                  </Typography>
                  <Typography variant="h4" color="text.primary">
                    {issueCountsBySeverity?.[severity] || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vulnerabilities
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        ))}
      </Stack>
    </Grid>
  );
}
