import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface DashboardStore {
  selectedTargetId: string | null;
  setSelectedTargetId: (targetId: string | null) => void;
}

export const useDashboardStore = create<DashboardStore>()(
  persist(
    (set) => ({
      selectedTargetId: null,
      setSelectedTargetId: (selectedTargetId) => set({ selectedTargetId }),
    }),
    {
      name: 'redteam-dashboard-storage',
    },
  ),
);
