import ReactMarkdown from 'react-markdown';
import analyzingPanda from '@cloud-ui/assets/analyzing_panda.jpg';
import { Typography, Alert, AlertTitle, Box } from '@mui/material';
import { type RemediationDTO } from '@shared/dto';

export default function Explanation({ remediation }: { remediation: RemediationDTO }) {
  return (
    <div>
      {/* Add navigation links */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2">
          <a href="#remediation">Custom Analysis and Remediation Suggestions</a> |{' '}
          <a href="#generic-description">Vulnerability Background and Detailed Description</a>
        </Typography>
      </Box>

      {/* Remediation section */}
      <div id="remediation">
        {remediation?.remediation ? (
          <ReactMarkdown>{remediation?.remediation}</ReactMarkdown>
        ) : (
          <Alert
            severity="info"
            icon={
              <Box
                component="img"
                src={analyzingPanda}
                alt="Analyzing Panda"
                sx={{ width: 40, height: 40, objectFit: 'cover', borderRadius: '50%' }}
              />
            }
            sx={{ mb: 2, alignItems: 'center' }}
          >
            <AlertTitle>Analysis in Progress</AlertTitle>
            Analyzing results and generating custom remediations for you... In the meantime, you can
            read more about the issue below.
          </Alert>
        )}
      </div>

      {/* Generic Description section */}
      <div id="generic-description">
        <Typography variant="body2">
          {remediation?.genericDescription ? (
            <ReactMarkdown>{remediation?.genericDescription}</ReactMarkdown>
          ) : (
            <Typography variant="body2">No generic description available.</Typography>
          )}
        </Typography>
      </div>
    </div>
  );
}
