import React from 'react';
import { Paper, List } from '@mui/material';
import type { IssueDTO } from '@shared/dto';
import IssueItem from './IssueItem';

interface IssueListProps {
  issues: IssueDTO[];
  isCompact: boolean;
  selectedIssue: IssueDTO | null;
}

export default function IssueList({ issues, isCompact, selectedIssue }: IssueListProps) {
  return (
    <Paper elevation={2}>
      <List>
        {issues.map((issue) => (
          <IssueItem
            key={issue.id}
            issue={issue}
            isCompact={isCompact}
            isSelected={selectedIssue?.id === issue.id}
          />
        ))}
      </List>
    </Paper>
  );
}
