import { Link } from 'react-router-dom';
import { EVAL_ID_SCAN_PATTERN, ROUTES } from '@cloud-ui/constants';
import { Paper, Box, Typography, Chip } from '@mui/material';
import type { IssueHistoryDTO } from '@shared/dto';

interface HistoryItemProps {
  item: IssueHistoryDTO;
}

export default function HistoryItem({ item }: HistoryItemProps) {
  const scanMatch = item.text.match(EVAL_ID_SCAN_PATTERN);

  return (
    <Paper elevation={1} sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {item.name}
        </Typography>
        <Chip
          label={new Date(item.createdAt).toLocaleString()}
          size="small"
          variant="outlined"
          color="primary"
        />
      </Box>
      <Typography variant="body1" sx={{ mt: 1 }}>
        {scanMatch ? (
          <>
            {item.text.substring(0, scanMatch.index)} from scan:{' '}
            <Link to={`${ROUTES.eval}/${scanMatch[1]}`}>{scanMatch[1]}</Link>
          </>
        ) : (
          item.text
        )}
      </Typography>
    </Paper>
  );
}
