import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, keyframes } from '@mui/material/styles';

const subtleAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  background: 'linear-gradient(-45deg, #FF6B6B, #4A4A4A, #3498DB, #8E44AD, #2C3E50)',
  backgroundSize: '400% 400%',
  animation: `${subtleAnimation} 15s ease infinite`,
  transition: theme.transitions.create(['transform', 'box-shadow']),
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  },
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Inter", sans-serif',
  fontWeight: 600,
  fontSize: '1.6rem',
  color: '#ffffff',
  letterSpacing: '0.02em',
}));

const CloudText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Inter", sans-serif',
  fontWeight: 500,
  fontSize: '0.8rem',
  color: 'rgba(255, 255, 255, 0.9)',
  marginLeft: theme.spacing(1),
  padding: theme.spacing(0.3, 0.8),
  borderRadius: '8px',
  background: 'rgba(255, 255, 255, 0.15)',
  backdropFilter: 'blur(4px)',
}));

export default function Logo() {
  return (
    <RouterLink to="/" style={{ textDecoration: 'none' }}>
      <LogoWrapper>
        <LogoText variant="h1">promptfoo</LogoText>
        <CloudText variant="caption">cloud</CloudText>
      </LogoWrapper>
    </RouterLink>
  );
}
